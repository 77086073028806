import React from "react";
import { Header } from "../components/header";
import { About } from "../components/about";
import JsonData from "../data/data.json";
import Timeline from "../components/Timeline";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Home = () => {
  // Define timeline items for the Home page
  const companyMilestones = [
    {
      year: "2022",
      description: "Reached $100M in annual sales.",
      image: "/img/Production/IMG_3303.jpg"
    },
    {
      year: "2015",
      description: "Reached $50M in annual sales. Moreover, increased our monthly production capacity to 150,000 garments.",
      image: "/img/Production/IMG_3302.jpg"
    },
    {
      year: "2013",
      description: "Increased our monthly production capacity to 50,000 garments.",
      image: "/img/Production/IMG_3277.jpg"
    },
    {
      year: "2012",
      description: "Expanded our facilities and workforce to meet growing demands.",
      image: "/img/Production/IMG_3299 copy.jpg"
    },
    {
      year: "2010",
      description: "Started our own Denim Manufacturing Unit 30,000 Garments per month Capacity.",
      image: "/img/Production/IMG_3294 copy.jpg"
    },
    {
      year: "2007",
      description: "Softwood was established with the mission to bridge the gap between customers and suppliers providing Sourcing and Quality Services.",
      image: "/img/Production/fornt.jpg"
    }
  ];

  // Define certification logos
  const certifications = [
    { src: "/img/Logos/GOTS.png", alt: "GOTS Certification" },
    { src: "/img/Logos/GRS.jpg", alt: "GRS Certification" },
    { src: "/img/Logos/OCS.png", alt: "OCS Certification" },
    { src: "/img/Logos/RCS.jpg", alt: "RCS Certification" },
    { src: "/img/Logos/oeko.png", alt: "OEKO-TEX Certification" },
    { src: "/img/Logos/bci.png", alt: "BCI Certification" },
    { src: "/img/Logos/BSCI-Certification-amfori.png", alt: "BSCI Certification" }
  ];

  // Define partner logos
  const partners = [
    "kocca.png", "manifatura.png", "terranova.png", "carisma.png", "carry.png",
    "hering.png", "frabo.png", "charanga.png", "kingsley.png", "code.png",
    "dos galgos.png", "bigdude.png", "soul star.png", "noppies.png", "riu paris.png",
    "coccodrilo.png", "chicore.png", "lanidor.png", "vilanova.png", "punt roma.png",
    "zebra.png", "orsay.png", "reserved.png", "house.png", "gas.png",
    "mise au green.png", "pinkwoman.png", "blue seven.png", "authentic.png"
  ];

  return (
    <div>
      <Header data={JsonData.Header} />
      <About data={JsonData.About} />
      
      {/* Services/Features Section */}
      <div className="container my-5 py-5">
        <div className="row text-center mb-5">
          <div className="col-12">
            <h2 style={{ 
              fontSize: "2.5rem", 
              fontWeight: "700", 
              color: "#333", 
              marginBottom: "1rem" 
            }}>Our Services</h2>
          </div>
        </div>

        <div className="row g-4">
          <div className="col-md-6 mb-4">
            <div className="card h-100" style={{ 
              borderRadius: "12px", 
              overflow: "hidden",
              boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
              border: "none"
            }}>
              <div style={{ height: "300px", overflow: "hidden" }}>
                <img 
                  src="/img/home/11062b_41ebe635b0f84102aeec826337eb8a5d~mv2.png" 
                  alt="Fabric Samples" 
                  style={{ 
                    width: "100%", 
                    height: "100%", 
                    objectFit: "cover", 
                    transition: "transform 0.3s ease", 
                  }}
                  onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.05)"}
                  onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
                />
              </div>
              <div className="card-body p-4">
                <h3 className="card-title" style={{ 
                  fontSize: "2rem", 
                  fontWeight: "600", 
                  marginBottom: "1rem" ,
                  paddingLeft:12,
                  paddingRight:12
                }}>Best Sourcing for textiles</h3>
                <p className="card-text" style={{ 
                  fontSize: "1.1rem", 
                  color: "#666", 
                  marginBottom: "1.5rem" ,
                  paddingLeft:12,
                  paddingRight:12
                }}>
                  Sourcing textiles from Pakistan is our core competency, and we always deliver on our promises to our customers.
                </p>
                <a href="/sourcing" className="btn btn-primary" style={{ 
                  padding: "0.5rem 2rem", 
                  fontSize: "1.1rem", 
                  borderRadius: "30px" ,
                  marginLeft:12,
                  marginRight:12,
                  marginBottom:12
                }}>Explore</a>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="card h-100" style={{ 
              borderRadius: "12px", 
              overflow: "hidden",
              boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
              border: "none"
            }}>
              <div style={{ height: "300px", overflow: "hidden" }}>
                <img 
                  src="/img/Production/pro1.jpg" 
                  alt="Production" 
                  style={{ 
                    width: "100%", 
                    height: "100%", 
                    objectFit: "cover", 
                    transition: "transform 0.3s ease" 
                  }}
                  onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.05)"}
                  onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
                />
              </div>
              <div className="card-body p-4">
                <h3 className="card-title" style={{ 
                  fontSize: "2rem", 
                  fontWeight: "600", 
                  marginBottom: "1rem" ,
                  paddingLeft:12,
                  paddingRight:12
                }}>Our Production</h3>
                <p className="card-text" style={{ 
                  fontSize: "1.1rem", 
                  color: "#666", 
                  marginBottom: "1.5rem" ,
                  paddingLeft:12,
                  paddingRight:12
                }}>
                  Depending on your requirements you can also choose our dedicated in-house production facility with a capacity of 150,000 garments which is achieved with a workforce of 600+ trained professionals.
                </p>
                <a href="/production" className="btn btn-primary" style={{ 
                  padding: "0.5rem 2rem", 
                  fontSize: "1.1rem", 
                  borderRadius: "30px" ,
                  marginLeft:12,
                  marginRight:12,
                  marginBottom:12
                }}>Explore</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Timeline Component */}
      <div className="my-5">
        <Timeline title="Our Journey" items={companyMilestones} />
      </div>

      {/* Certifications Slider Section */}
      <div className="container-fluid py-5" style={{ marginTop:24 }}>
        <div className="container py-4">
          <div className="row text-center mb-5">
            <div className="col-12">
              <h2 style={{ 
                fontSize: "2.5rem", 
                fontWeight: "700", 
                color: "#333", 
                marginBottom: "1rem",
                marginTop:24
              }}>Our Certifications</h2>
              <p style={{ 
                fontSize: "1.3rem", 
                color: "#666" 
              }}>Quality & Sustainability Standards</p>
            </div>
          </div>

          <Swiper
            modules={[Autoplay, Navigation, Pagination]}
            spaceBetween={30}
            slidesPerView={3}
            navigation
            pagination={{ clickable: true }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30
              }
            }}
          >
            {certifications.map((cert, index) => (
              <SwiperSlide key={index}>
                <div className="text-center p-4">
                  <img 
                    src={cert.src} 
                    alt={cert.alt}
                    style={{ 
                      maxWidth: "100%", 
                      height: "auto", 
                      maxHeight: "180px",
                      filter: "grayscale(0.2)",
                      transition: "transform 0.3s ease",
                      paddingBottom:24
                    }}
                    onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.05)"}
                    onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      {/* Partners Section */}
      <div className="container my-5 py-5">
        <div className="row text-center mb-5">
          <div className="col-12">
            <h2 style={{ 
              fontSize: "2.5rem", 
              fontWeight: "700", 
              color: "#333", 
              marginBottom: "1rem",
              marginTop:24
            }}>Our Partners</h2>
            <p style={{ 
              fontSize: "1.3rem", 
              color: "#666" 
            }}>Trusted by Leading Brands Worldwide</p>
          </div>
        </div>

        <div className="row g-4">
          {partners.map((img, index) => (
            <div key={index} className="col-md-3 col-6 text-center mb-4">
              <div style={{ 
                padding: "1.5rem", 
                height: "120px", 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center",
                background: "#fff",
                borderRadius: "8px",
                boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
                transition: "transform 0.3s ease, box-shadow 0.3s ease"
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = "translateY(-5px)";
                e.currentTarget.style.boxShadow = "0 5px 15px rgba(0,0,0,0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
                e.currentTarget.style.boxShadow = "0 2px 8px rgba(0,0,0,0.05)";
              }}>
                <img 
                  src={`/img/home/Partners/${img}`}
                  alt={`Partner ${index + 1}`}
                  style={{ 
                    maxWidth: "100%", 
                    maxHeight: "100%", 
                    objectFit: "contain" 
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home; 
