import React, { useState, useEffect } from "react";
import JsonData from "../data/data.json";

const Sourcing = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  
  // Auto-rotate slides every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prevSlide) => (prevSlide + 1) % 6);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="sourcing-section" style={{ paddingTop: "60px", paddingBottom: "60px" }}>
      <div className="container">
        {/* Hero Image */}
        <div className="row mb-5">
          <div className="col-12">
            <img 
              src="/img/Sourcing/11062b_7fabaf4a8d5c4c46aba0c66ddc335ebb~mv2.png" 
              alt="Softwood Sourcing" 
              style={{
                width: "100%",
                height: "300px",
                objectFit: "cover",
                borderRadius: "12px",
                marginBottom: "2rem"
              }}
            />
          </div>
        </div>

        {/* Header */}
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h1 style={{ 
              fontSize: "3.2rem", 
              fontWeight: "600", 
              color: "#333",
              marginBottom: "1.5rem"
            }}>Sourcing Excellence</h1>
            <p style={{ 
              fontSize: "1.4rem", 
              color: "#666", 
              maxWidth: "800px", 
              margin: "0 auto",
              lineHeight: "1.6"
            }}>
              Softwood's core competency lies in sourcing all types of textiles from Pakistan. 
              We are committed to delivering on our promises to our customers with quality, reliability, and transparency.
            </p>
          </div>
        </div>

        {/* Main Content */}
        <div className="row mb-5">
          <div className="col-12">
            <div className="p-4" style={{ 
              background: "#f8f9fa", 
              borderRadius: "12px",
              border: "1px solid #eee",
              marginTop: "1.5rem"

            }}>
              <h2 style={{ 
                fontSize: "2.2rem", 
                fontWeight: "600", 
                color: "#333",
                marginBottom: "1.5rem",
                marginTop: "1.5rem"
              }}>Our Sourcing Expertise</h2>
              <p style={{ 
                fontSize: "1.2rem", 
                lineHeight: "1.6", 
                color: "#555",
                marginBottom: "1.5rem"
              }}>
                Softwood's expertise in garment sourcing involves navigating complex operations, shorter lead times, 
                variable order sizes, and a wide range of products. Our skilled merchandisers and quality professionals 
                work together to bridge the gap between buyers and sellers, offering the best solutions in terms of quality and price.
              </p>
              <p style={{ 
                fontSize: "1.2rem", 
                lineHeight: "1.6", 
                color: "#555",
                marginBottom: "1.5rem"
              }}>
                Our independent quality control department is dedicated to achieving customer standards through a transparent 
                and efficient process. Our Quality Auditors conduct in-line inspections and audits to ensure that our suppliers 
                follow all required quality parameters.
              </p>
              <p style={{ 
                fontSize: "1.2rem", 
                lineHeight: "1.6", 
                color: "#555"
              }}>
                In addition, Softwood offers an Online Reporting system to keep our customers informed about their order status at all times.
              </p>
            </div>
          </div>
        </div>

        {/* Product Categories */}
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 style={{ 
              fontSize: "2.2rem", 
              fontWeight: "600", 
              color: "#333",
              marginBottom: "1.5rem",
              marginTop: "1.5rem",
            }}>Our Product Categories</h2>
            <p style={{ 
              fontSize: "1.2rem", 
              color: "#666", 
              maxWidth: "800px", 
              margin: "0 auto 2rem",
              lineHeight: "1.6"
            }}>
              We offer a wide range of product categories to meet your specific needs
            </p>
          </div>
        </div>

        {/* Product Category Cards */}
        <div className="row g-4 mb-5">
          {[
            {
              title: "Denim Wear",
              image: "/img/Sourcing/denim.jpg",
              description: "High-quality denim products crafted with precision & accuracy."
            },
            {
              title: "Knit Wear",
              image: "/img/Sourcing/4.jpg",
              description: "Comfortable and stylish knitwear for all seasons and occasions."
            },
            {
              title: "Jackets",
              image: "/img/Sourcing/l1.png",
              description: "Premium jackets designed for both fashion and functionality."
            },
            {
              title: "Home Textiles",
              image: "/img/Sourcing/f2967afd168f6ff55d5b48c29e96563c.jpg",
              description: "Elegant home textile products to enhance your living spaces."
            },
            {
              title: "Fabrics",
              image: "/img/Sourcing/AdobeStock_332076102.jpeg",
              description: "High-quality fabrics for various applications and industries."
            },
            {
              title: "Workwear",
              image: "/img/Sourcing/w2.jpg",
              description: "Durable and functional workwear designed for safety and comfort."
            }
          ].map((card, index) => (
            <div key={index} className="col-md-4 col-sm-6 mb-4">
              <div className="card" 
                style={{ 
                  borderRadius: "8px",
                  overflow: "hidden",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                  transition: "all 0.2s ease",
                  cursor: "pointer",
                  border: "1px solid #eee",
                  padding: '8px',
                  marginBottom: 12,
                  marginTop:12,
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = "translateY(-5px)";
                  e.currentTarget.style.boxShadow = "0 5px 15px rgba(0,0,0,0.1)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow = "0 2px 8px rgba(0,0,0,0.1)";
                }}>
                <div style={{ position: "relative", height: "320px" }}>
                  <img 
                    src={card.image}
                    alt={card.title}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover"
                    }}
                  />
                </div>
                <div className="p-3">
                  <h5 style={{ 
                    fontSize: "1.4rem", 
                    fontWeight: "600", 
                    color: "#333",
                    marginBottom: "0.75rem"
                  }}>{card.title}</h5>
                  <p className="mb-0" style={{ 
                    fontSize: "1.1rem", 
                    lineHeight: "1.5", 
                    color: "#666"
                  }}>
                    {card.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Image Slider */}
        <div className="row mb-5">
          <div className="col-12">
            <h2 style={{ 
              fontSize: "2.2rem", 
              fontWeight: "600", 
              color: "#333",
              marginBottom: "1.5rem",
              textAlign: "center"
            }}>Our Product Gallery</h2>
            
            <div style={{ 
              position: "relative", 
              height: "400px", 
              overflow: "hidden",
              borderRadius: "12px",
              boxShadow: "0 4px 12px rgba(0,0,0,0.1)"
            }}>
              {/* Slider Images */}
              <div style={{ 
                display: "flex", 
                transition: "transform 0.5s ease",
                height: "100%",
                transform: `translateX(-${activeSlide * 100}%)`
              }}>
                <div style={{ minWidth: "100%", height: "100%" }}>
                  <img 
                    src="/img/Sourcing/denim.jpg" 
                    alt="Denim Products" 
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                </div>
                <div style={{ minWidth: "100%", height: "100%" }}>
                  <img 
                    src="/img/Sourcing/f2967afd168f6ff55d5b48c29e96563c.jpg" 
                    alt="Home Textiles" 
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                </div>
                <div style={{ minWidth: "100%", height: "100%" }}>
                  <img 
                    src="/img/Sourcing/w1.png" 
                    alt="Workwear" 
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                </div>
                <div style={{ minWidth: "100%", height: "100%" }}>
                  <img 
                    src="/img/Sourcing/4.jpg" 
                    alt="Knit Wear" 
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                </div>
                <div style={{ minWidth: "100%", height: "100%" }}>
                  <img 
                    src="/img/Sourcing/l1.png" 
                    alt="Jackets" 
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                </div>
                <div style={{ minWidth: "100%", height: "100%" }}>
                  <img 
                    src="/img/Sourcing/w2.jpg" 
                    alt="Workwear Collection" 
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                </div>
              </div>
              
              {/* Slider Controls */}
              <div style={{ 
                position: "absolute", 
                bottom: "20px", 
                left: "50%", 
                transform: "translateX(-50%)", 
                display: "flex", 
                gap: "10px" 
              }}>
                {[0, 1, 2, 3, 4, 5].map((index) => (
                  <button 
                    key={index}
                    onClick={() => setActiveSlide(index)}
                    style={{ 
                      width: "12px", 
                      height: "12px", 
                      borderRadius: "50%", 
                      border: "none", 
                      background: activeSlide === index ? "#007bff" : "rgba(255,255,255,0.5)",
                      cursor: "pointer",
                      transition: "background 0.3s ease"
                    }}
                  />
                ))}
              </div>
              
              {/* Slider Arrows */}
              <button 
                onClick={() => setActiveSlide((prev) => (prev - 1 + 6) % 6)}
                style={{ 
                  position: "absolute", 
                  left: "20px", 
                  top: "50%", 
                  transform: "translateY(-50%)", 
                  background: "rgba(255,255,255,0.7)", 
                  border: "none", 
                  borderRadius: "50%", 
                  width: "40px", 
                  height: "40px", 
                  display: "flex", 
                  alignItems: "center", 
                  justifyContent: "center",
                  cursor: "pointer",
                  fontSize: "1.5rem",
                  color: "#333"
                }}
              >
                ‹
              </button>
              <button 
                onClick={() => setActiveSlide((prev) => (prev + 1) % 6)}
                style={{ 
                  position: "absolute", 
                  right: "20px", 
                  top: "50%", 
                  transform: "translateY(-50%)", 
                  background: "rgba(255,255,255,0.7)", 
                  border: "none", 
                  borderRadius: "50%", 
                  width: "40px", 
                  height: "40px", 
                  display: "flex", 
                  alignItems: "center", 
                  justifyContent: "center",
                  cursor: "pointer",
                  fontSize: "1.5rem",
                  color: "#333"
                }}
              >
                ›
              </button>
            </div>
          </div>
        </div>

        {/* CTA */}
        <div className="row mt-5">
          <div className="col-12 text-center">
            <div className="p-4" style={{ 
              background: "#f8f9fa", 
              borderRadius: "8px",
              border: "1px solid #eee"
            }}>
              <h3 style={{ 
                fontSize: "2rem", 
                fontWeight: "600", 
                color: "#333", 
                marginBottom: "1rem" 
              }}>Ready to Source with Softwood?</h3>
              <p style={{ 
                fontSize: "1.2rem", 
                color: "#666", 
                marginBottom: "1.5rem" 
              }}>Contact us today to discuss your sourcing needs and discover how we can help.</p>
              <a href="/contact" className="btn btn-primary" style={{ 
                padding: "0.5rem 1.5rem",
                fontSize: "1.1rem"
              }}>
                Get in Touch
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sourcing; 
