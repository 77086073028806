import React from "react";
import whyUsImage1 from "../assets/Why Us/car.png";
import whyUsImage2 from "../assets/Why Us/Image by Scott Graham.png";
import whyUsImage3 from "../assets/Why Us/Image by Myriam Jessier.png";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="about-images">
              <img src={whyUsImage1} className="img-responsive" alt="Why Us 1" />
              <img src={whyUsImage2} className="img-responsive" alt="Why Us 2" />
              <img src={whyUsImage3} className="img-responsive" alt="Why Us 3" />
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Our Vision</h3>
              <blockquote>
                {props.data ? props.data.vision : "loading..."}
              </blockquote>
              <h3>Our Mission</h3>
              <blockquote>
                {props.data ? props.data.mission : "loading..."}
              </blockquote>
              <h3>Core Values</h3>
              <blockquote>
              <ul className="list-unstyled">
                  <li className="mb-2">Encouraging Individual Initiatives</li>
                  <li className="mb-2">Encouraging & Enhancing Individual Ability</li>
                  <li className="mb-2">Affordable But Enthusiastic Pursuit of Technology</li>
                </ul>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
