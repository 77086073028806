import React from "react";

const Blog = () => {
  return (
    <div className="blog-section" style={{ paddingTop: "80px" }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 mb-4">
            <img 
              src="/img/intro-bg.jpg" 
              alt="Blog Coming Soon" 
              className="img-fluid rounded shadow"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div className="col-md-6 mb-4">
            <h2 className="mb-4">Blog</h2>
            <div className="blog-content">
              <p className="lead mb-4">Coming soon! Stay tuned for our latest news and updates.</p>
              <div className="coming-soon-features">
                <h3 className="mb-3">What to Expect</h3>
                <ul className="list-unstyled">
                  <li className="mb-2">✓ Industry insights and trends</li>
                  <li className="mb-2">✓ Company news and updates</li>
                  <li className="mb-2">✓ Sustainability initiatives</li>
                  <li className="mb-2">✓ Success stories and case studies</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog; 
