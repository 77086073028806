import React from 'react';

const Timeline = ({ title, items }) => {
  return (
    <div className="timeline-section py-5" style={{ 
      background: "linear-gradient(to bottom, #f8f9fa 0%, #ffffff 100%)",
      padding: "80px 0"
    }}>
      <div className="container">
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 style={{ 
              fontSize: "2.8rem", 
              fontWeight: "700", 
              color: "#333", 
              marginBottom: "1.5rem",
              position: "relative",
              display: "inline-block"
            }}>
              {title || "Our Milestones"}
              <div style={{
                position: "absolute",
                bottom: "-10px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "80px",
                height: "4px",
                background: "linear-gradient(90deg, #007bff, #00bcd4)",
                borderRadius: "2px"
              }}></div>
            </h2>
            <p style={{ 
              fontSize: "1.3rem", 
              color: "#666", 
              maxWidth: "800px", 
              margin: "2rem auto 4rem",
              lineHeight: "1.6"
            }}>The journey of Softwood Textiles through the years</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div style={{ position: "relative" }}>
              {/* Vertical Line */}
              <div style={{ 
                position: "absolute", 
                left: "50%", 
                transform: "translateX(-50%)", 
                width: "3px", 
                height: "100%", 
                background: "linear-gradient(to bottom, #007bff, #00bcd4)",
                zIndex: 1,
                opacity: 0.3
              }}></div>

              {/* Timeline Items */}
              {items && items.map((item, index) => (
                <div key={index} className="row mb-5" style={{ position: "relative" }}>
                  {index % 2 === 0 ? (
                    // Left side content
                    <>
                      <div className="col-md-5 text-md-end pe-md-4">
                        <div style={{ 
                          background: "white",
                          padding: "30px",
                          borderRadius: "16px",
                          boxShadow: "0 10px 30px rgba(0,0,0,0.08)",
                          transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
                          border: "1px solid rgba(0,0,0,0.05)",
                          position: "relative",
                          overflow: "hidden"
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "translateY(-8px)";
                          e.currentTarget.style.boxShadow = "0 15px 35px rgba(0,0,0,0.1)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "translateY(0)";
                          e.currentTarget.style.boxShadow = "0 10px 30px rgba(0,0,0,0.08)";
                        }}>
                          <div style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "4px",
                            height: "100%",
                            background: "linear-gradient(to bottom, #007bff, #00bcd4)",
                            borderRadius: "2px"
                          }}></div>
                          <div style={{ paddingLeft: "20px" }}>
                            <h3 style={{ 
                              fontSize: "2.2rem", 
                              fontWeight: "700", 
                              color: "#333",
                              marginBottom: "16px",
                              background: "linear-gradient(90deg, #007bff, #00bcd4)",
                              WebkitBackgroundClip: "text",
                              WebkitTextFillColor: "transparent"
                            }}>{item.year}</h3>
                            <p style={{ 
                              fontSize: "1.3rem", 
                              lineHeight: "1.6",
                              color: "#666",
                              fontWeight: "400",
                              margin: 0
                            }}>{item.description}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 d-flex justify-content-center">
                        <div style={{ 
                          position: "relative", 
                          width: "160px", 
                          height: "160px",
                          borderRadius: "50%",
                          overflow: "hidden",
                          border: "4px solid white",
                          zIndex: 2,
                          boxShadow: "0 10px 25px rgba(0,0,0,0.15)",
                          margin: "20px 0",
                          transition: "all 0.4s ease"
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.1)";
                          e.currentTarget.style.boxShadow = "0 15px 35px rgba(0,0,0,0.2)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.boxShadow = "0 10px 25px rgba(0,0,0,0.15)";
                        }}>
                          <img 
                            src={item.image || "/img/Production/pro1.jpg"} 
                            alt={item.title} 
                            style={{
                              width: "100%",
                              height: "100%",
                              transition: "transform 0.4s ease"
                            }}
                          />
                          <div style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: "linear-gradient(45deg, rgba(0,123,255,0.2), rgba(0,188,212,0.2))",
                            opacity: 0,
                            transition: "opacity 0.4s ease"
                          }}
                          onMouseOver={(e) => e.currentTarget.style.opacity = "1"}
                          onMouseOut={(e) => e.currentTarget.style.opacity = "0"}
                          ></div>
                        </div>
                      </div>
                      <div className="col-md-5"></div>
                    </>
                  ) : (
                    // Right side content
                    <>
                      <div className="col-md-5"></div>
                      <div className="col-md-2 d-flex justify-content-center">
                        <div style={{ 
                          position: "relative", 
                          width: "160px", 
                          height: "160px",
                          borderRadius: "50%",
                          overflow: "hidden",
                          border: "4px solid white",
                          zIndex: 2,
                          boxShadow: "0 10px 25px rgba(0,0,0,0.15)",
                          margin: "20px 0",
                          transition: "all 0.4s ease"
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.1)";
                          e.currentTarget.style.boxShadow = "0 15px 35px rgba(0,0,0,0.2)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.boxShadow = "0 10px 25px rgba(0,0,0,0.15)";
                        }}>
                          <img 
                            src={item.image || "/img/Production/pro1.jpg"} 
                            alt={item.title} 
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              transition: "transform 0.4s ease"
                            }}
                          />
                          <div style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: "linear-gradient(45deg, rgba(0,123,255,0.2), rgba(0,188,212,0.2))",
                            opacity: 0,
                            transition: "opacity 0.4s ease"
                          }}
                          onMouseOver={(e) => e.currentTarget.style.opacity = "1"}
                          onMouseOut={(e) => e.currentTarget.style.opacity = "0"}
                          ></div>
                        </div>
                      </div>
                      <div className="col-md-5 text-md-start ps-md-4">
                        <div style={{ 
                          background: "white",
                          padding: "30px",
                          borderRadius: "16px",
                          boxShadow: "0 10px 30px rgba(0,0,0,0.08)",
                          transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
                          border: "1px solid rgba(0,0,0,0.05)",
                          position: "relative",
                          overflow: "hidden"
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "translateY(-8px)";
                          e.currentTarget.style.boxShadow = "0 15px 35px rgba(0,0,0,0.1)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "translateY(0)";
                          e.currentTarget.style.boxShadow = "0 10px 30px rgba(0,0,0,0.08)";
                        }}>
                          <div style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: "4px",
                            height: "100%",
                            background: "linear-gradient(to bottom, #007bff, #00bcd4)",
                            borderRadius: "2px"
                          }}></div>
                          <div style={{ paddingRight: "20px" }}>
                            <h3 style={{ 
                              fontSize: "2.2rem", 
                              fontWeight: "700", 
                              color: "#333",
                              marginBottom: "16px",
                              background: "linear-gradient(90deg, #007bff, #00bcd4)",
                              WebkitBackgroundClip: "text",
                              WebkitTextFillColor: "transparent"
                            }}>{item.year}</h3>
                            <p style={{ 
                              fontSize: "1.3rem", 
                              lineHeight: "1.6",
                              color: "#666",
                              fontWeight: "400",
                              margin: 0
                            }}>{item.description}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Example items to show how to use the component
const defaultItems = [
  {
    year: "2022",
    description: "Reached $100M in annual sales.",
    image: "/img/Production/IMG_3303.jpg"
  },
  {
    year: "2015",
    description: "Reached $50M in annual sales. Moreover, increased our monthly production capacity to 150,000 garments.",
    image: "/img/Production/IMG_3302.jpg"
  },
  {
    year: "2013",
    description: "Increased our monthly production capacity to 50,000 garments.",
    image: "/img/Production/IMG_3300.jpg"
  },
  {
    year: "2012",
    description: "Expanded our facilities and workforce to meet growing demands.",
    image: "/img/Production/IMG_3299 copy.jpg"
  }
];

// Default props to demonstrate usage
Timeline.defaultProps = {
  title: "Our Milestones",
  items: defaultItems
};

export default Timeline; 
