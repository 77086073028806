import React, { useState } from "react";
import JsonData from "../data/data.json";

const Sustainability = () => {
  const [activeTab, setActiveTab] = useState(0);

  const sustainabilityInitiatives = [
    {
      title: "Water Treatment Plants & Water Saving Techniques",
      description: "We use Effluent Treatment Plants to minimise soil and water contamination. In addition, we implement processes that conserve water during laundry processes.",
      image: "/img/Sustainability/11062b_84fd4f3425564c84818db9f6c5a1f071~mv2_d_5616_3744_s_4_2.png"
    },
    {
      title: "Renewable Power Sources & Energy Audits",
      description: "In our efforts to reduce our carbon footprint, we have integrated the use of solar energy into our operations, and we are continually increasing its use. Additionally, we conduct regular energy audits and implement measures to conserve energy.",
      image: "/img/Sustainability/Sunset on Solar Panels.png"
    },
    {
      title: "Investing in People",
      description: "We prioritize creating value for our customers, suppliers, employees, and community members. Our commitment to creating mutual benefits for all stakeholders drives our business practices and contributes to our sustainable growth and success.",
      image: "/img/Sustainability/Screenshot 2022-07-25 at 3_08_40 PM.png"
    },
    {
      title: "Responsible Sourcing",
      description: "We maintain a sustainable supply chain by promoting the use of recycled, organically produced, and sustainable materials.",
      image: "/img/Sustainability/11062b_84fd4f3425564c84818db9f6c5a1f071~mv2_d_5616_3744_s_4_2.png"
    }
  ];

  const certifications = [
    {
      name: "GOTS Certified",
      image: "/img/Logos/GOTS.png",
      description: "Global Organic Textile Standard certification for organic textiles"
    },
    {
      name: "GRS Certified",
      image: "/img/Logos/GRS.jpg",
      description: "Global Recycled Standard certification for recycled content"
    },
    {
      name: "OCS Certified",
      image: "/img/Logos/OCS.png",
      description: "Organic Content Standard certification"
    },
    {
      name: "RCS Certified",
      image: "/img/Logos/RCS.jpg",
      description: "Recycled Claim Standard certification"
    },
    {
      name: "OEKO-TEX",
      image: "/img/Logos/oeko.png",
      description: "STANDARD 100 by OEKO-TEX® certification"
    },
    {
      name: "BSCI",
      image: "/img/Logos/BSCI-Certification-amfori.png",
      description: "Business Social Compliance Initiative certification"
    },
    {
      name: "BCI",
      image: "/img/Logos/bci.png",
      description: "Better Cotton Initiative member"
    }
  ];

  return (
    <div className="sustainability-section" style={{ paddingTop: "80px", paddingBottom: "60px" }}>
      <div className="container">
        {/* Hero Section */}
        <div className="row mb-5">
          <div className="col-12">
            <div style={{ 
              position: "relative", 
              height: "400px", 
              overflow: "hidden", 
              borderRadius: "12px",
              boxShadow: "0 4px 12px rgba(0,0,0,0.1)"
            }}>
              <img 
                src="/img/Sustainability/Sunset on Solar Panels.png" 
                alt="Sustainability" 
                style={{ 
                  width: "100%", 
                  height: "100%", 
                  objectFit: "cover" 
                }}
              />
              <div style={{ 
                position: "absolute", 
                top: 0, 
                left: 0, 
                right: 0, 
                bottom: 0, 
                background: "rgba(0,0,0,0.4)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                color: "white",
                textAlign: "center",
                padding: "0 20px"
              }}>
                <h1 style={{ 
                  fontSize: "3.2rem", 
                  fontWeight: "600", 
                  marginBottom: "1rem",
                  textShadow: "0 2px 4px rgba(0,0,0,0.3)"
                }}>Sustainability</h1>
                <p style={{ 
                  fontSize: "1.4rem", 
                  maxWidth: "800px",
                  textShadow: "0 1px 2px rgba(0,0,0,0.3)"
                }}>
                  Softwood's sustainability framework is centered around the triple bottom line, which prioritizes People, Planet, and Profit.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Introduction Section */}
        <div className="row mb-5">
          <div className="col-12">
            <div className="p-4" style={{ 
              background: "#f8f9fa", 
              borderRadius: "12px",
              border: "1px solid #eee",
              marginTop: "1.5rem",
              padding:8
            }}>
              <h2 style={{ 
                fontSize: "2.2rem", 
                fontWeight: "600", 
                color: "#333",
                marginBottom: "1.5rem",
                
              }}>Our Sustainability Commitment</h2>
              <p style={{ 
                fontSize: "1.2rem", 
                lineHeight: "1.6", 
                color: "#555",
                marginBottom: "1.5rem"
              }}>
                Softwood's sustainability framework is centered around the triple bottom line, which prioritizes People, Planet, and Profit. We are committed to implementing eco-friendly policies, promoting social welfare initiatives, and ensuring stable economic growth as we strive to build a sustainable business.
              </p>
              <p style={{ 
                fontSize: "1.2rem", 
                lineHeight: "1.6", 
                color: "#555"
              }}>
                To achieve this goal, we only work with partners who share our commitment to sustainability and operate in accordance with our policies.
              </p>
            </div>
          </div>
        </div>

        {/* Video Section */}
        <div className="row mb-5">
          <div className="col-12">
            <div className="p-4" style={{ 
              background: "#f8f9fa", 
              borderRadius: "12px",
              border: "1px solid #eee",
              marginTop:24
            }}>
              <h2 style={{ 
                fontSize: "2.2rem", 
                fontWeight: "600", 
                color: "#333",
                marginBottom: "1.5rem",
                marginTop: "1.5rem",
                textAlign: "center"
              }}>Our Sustainability Journey</h2>
              <div style={{ 
                position: "relative", 
                paddingBottom: "56.25%", 
                height: 0, 
                overflow: "hidden",
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0,0,0,0.1)"
              }}>
                <iframe 
                  style={{ 
                    position: "absolute", 
                    top: 0, 
                    left: 0, 
                    width: "100%", 
                    height: "100%",
                    border: "none"
                  }}
                  src="https://www.youtube.com/embed/3iSN_Xu5VGQ?start=8" 
                  title="Softwood Textiles Sustainability" 
                  frameBorder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        {/* Initiatives Section */}
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 style={{ 
              fontSize: "2.4rem", 
              fontWeight: "700", 
              color: "#333",
              marginBottom: "1.5rem",
              marginTop: "3rem",
              letterSpacing: "-0.5px"
            }}>Our Initiatives</h2>
            <p style={{ 
              fontSize: "1.3rem", 
              color: "#666", 
              maxWidth: "800px", 
              margin: "0 auto 3rem",
              lineHeight: "1.6"
            }}>
              Softwood along with it its partners continuously strives to improve its operations and make them sustainable
            </p>
          </div>
        </div>

        <div className="row g-5 mb-5">
          {sustainabilityInitiatives.map((initiative, index) => (
            <div key={index} className="col-md-6 col-lg-3">
              <div className="card h-100" 
                style={{ 
                  borderRadius: "16px",
                  overflow: "hidden",
                  boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
                  transition: "all 0.3s ease",
                  cursor: "pointer",
                  border: "1px solid #eee",
                  padding: "0 12px 20px 12px",
                  height:'70%'
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = "translateY(-8px)";
                  e.currentTarget.style.boxShadow = "0 8px 20px rgba(0,0,0,0.12)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow = "0 4px 12px rgba(0,0,0,0.08)";
                }}>
                <div style={{ position: "relative", height: "220px" }}>
                  <img 
                    src={initiative.image}
                    alt={initiative.title}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      paddingTop:12
                    }}
                  />
                  <div style={{ 
                    position: "absolute", 
                    top: "20px", 
                    left: "20px", 
                    background: "rgba(0, 123, 255, 0.9)", 
                    color: "white", 
                    padding: "10px 18px", 
                    borderRadius: "8px",
                    fontWeight: "700",
                    fontSize: "1.2rem",
                    boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
                    letterSpacing: "0.5px"
                  }}>
                    {String(index + 1).padStart(2, '0')}
                  </div>
                </div>
                <div className="p-4">
                  <h5 style={{ 
                    fontSize: "1.6rem", 
                    fontWeight: "700", 
                    color: "#333",
                    marginBottom: "1rem",
                    letterSpacing: "-0.3px",
                    lineHeight: "1.3"
                  }}>{initiative.title}</h5>
                  <p className="mb-0" style={{ 
                    fontSize: "1.1rem", 
                    lineHeight: "1.6", 
                    color: "#555",
                    letterSpacing: "0.2px"
                  }}>
                    {initiative.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>



        {/* CTA Section */}
        <div className="row">
          <div className="col-12 text-center">
            <div className="p-4" style={{ 
              background: "#f8f9fa", 
              borderRadius: "12px",
              border: "1px solid #eee",
              padding: "40px 30px",
              marginTop: "2rem"
            }}>
              <h3 style={{ 
                fontSize: "2.2rem", 
                fontWeight: "600", 
                color: "#333", 
                marginBottom: "1.5rem" 
              }}>Join Us in Our Sustainability Journey</h3>
              <p style={{ 
                fontSize: "1.3rem", 
                color: "#666", 
                marginBottom: "2rem",
                maxWidth: "800px",
                margin: "0 auto 2rem"
              }}>Partner with us to create a more sustainable future for the textile industry.</p>
              <a href="/contact" className="btn btn-primary" style={{ 
                padding: "0.75rem 2rem",
                fontSize: "1.2rem",
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0,123,255,0.2)",
                transition: "all 0.3s ease"
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = "translateY(-3px)";
                e.currentTarget.style.boxShadow = "0 6px 15px rgba(0,123,255,0.3)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
                e.currentTarget.style.boxShadow = "0 4px 12px rgba(0,123,255,0.2)";
              }}>
                Get in Touch
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sustainability; 
