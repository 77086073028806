import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer style={{
      background: "#f8f9fa",
      padding: "60px 0 30px",
      borderTop: "1px solid #eee"
    }}>
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-4">
            <img 
              src="/img/Logo.png" 
              alt="Softwood Textiles" 
              style={{
                height: "60px",
                marginBottom: "20px"
              }}
            />
            <p style={{
              fontSize: "1.1rem",
              color: "#666",
              lineHeight: "1.6",
              marginBottom: "20px"
            }}>
              Softwood Textiles is a leading manufacturer of high-quality garments, committed to sustainability and excellence in the textile industry.
            </p>
            <div style={{
              display: "flex",
              gap: "15px"
            }}>
              <a href="https://www.facebook.com/Softwoodtextiles/" target="_blank" rel="noopener noreferrer" style={{ color: "#666" }}>
                <i className="fa fa-facebook"></i>
              </a>
              <a href="https://www.linkedin.com/company/softwood" target="_blank" rel="noopener noreferrer" style={{ color: "#666" }}>
                <i className="fa fa-linkedin"></i>
              </a>
            </div>
          </div>
          
          <div className="col-md-2 mb-4">
            <h5 style={{
              fontSize: "1.2rem",
              fontWeight: "600",
              color: "#333",
              marginBottom: "20px"
            }}>Quick Links</h5>
            <ul style={{
              listStyle: "none",
              padding: 0,
              margin: 0
            }}>
              <li style={{ marginBottom: "10px" }}>
                <Link to="/" style={{
                  color: "#666",
                  textDecoration: "none",
                  fontSize: "1.1rem",
                  transition: "color 0.3s ease"
                }}>Home</Link>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <Link to="/why-us" style={{
                  color: "#666",
                  textDecoration: "none",
                  fontSize: "1.1rem",
                  transition: "color 0.3s ease"
                }}>Why Us</Link>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <Link to="/sourcing" style={{
                  color: "#666",
                  textDecoration: "none",
                  fontSize: "1.1rem",
                  transition: "color 0.3s ease"
                }}>Sourcing</Link>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <Link to="/production" style={{
                  color: "#666",
                  textDecoration: "none",
                  fontSize: "1.1rem",
                  transition: "color 0.3s ease"
                }}>Production</Link>
              </li>
            </ul>
          </div>
          
          <div className="col-md-2 mb-4">
            <h5 style={{
              fontSize: "1.2rem",
              fontWeight: "600",
              color: "#333",
              marginBottom: "20px"
            }}>Services</h5>
            <ul style={{
              listStyle: "none",
              padding: 0,
              margin: 0
            }}>
              <li style={{ marginBottom: "10px" }}>
                <Link to="/sustainability" style={{
                  color: "#666",
                  textDecoration: "none",
                  fontSize: "1.1rem",
                  transition: "color 0.3s ease"
                }}>Sustainability</Link>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <Link to="/careers" style={{
                  color: "#666",
                  textDecoration: "none",
                  fontSize: "1.1rem",
                  transition: "color 0.3s ease"
                }}>Careers</Link>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <Link to="/blog" style={{
                  color: "#666",
                  textDecoration: "none",
                  fontSize: "1.1rem",
                  transition: "color 0.3s ease"
                }}>Blog</Link>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <Link to="/contact" style={{
                  color: "#666",
                  textDecoration: "none",
                  fontSize: "1.1rem",
                  transition: "color 0.3s ease"
                }}>Contact</Link>
              </li>
            </ul>
          </div>
          
          <div className="col-md-4 mb-4">
            <h5 style={{
              fontSize: "1.2rem",
              fontWeight: "600",
              color: "#333",
              marginBottom: "20px"
            }}>Contact Info</h5>
            <ul style={{
              listStyle: "none",
              padding: 0,
              margin: 0
            }}>
              <li style={{ 
                marginBottom: "15px",
                display: "flex",
                alignItems: "flex-start",
                gap: "10px"
              }}>
                <i className="fas fa-map-marker-alt" style={{ color: "#666", marginTop: "5px" }}></i>
                <span style={{ color: "#666", fontSize: "1.1rem" }}>
                Head Office: 20 Km Ferozepur Road, Youhanabad Lahore, Pakistan
                France Office: 20 Rue Sandro Botticelli 68260, Kingersheim, France
                </span>
              </li>
              <li style={{ 
                marginBottom: "15px",
                display: "flex",
                alignItems: "center",
                gap: "10px"
              }}>
                <i className="fa fa-phone" style={{ color: "#666" }}></i>
                <span style={{ color: "#666", fontSize: "1.1rem" }}>
                +92-4235951191/92
                </span>
              </li>
              <li style={{ 
                marginBottom: "15px",
                display: "flex",
                alignItems: "center",
                gap: "10px"
              }}>
                <i className="fa fa-envelope" style={{ color: "#666" }}></i>
                <span style={{ color: "#666", fontSize: "1.1rem" }}>
                  info@softwoodtextiles.com
                </span>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="row" style={{ marginTop: "40px" }}>
          <div className="col-12">
            <hr style={{ borderColor: "#eee", marginBottom: "20px" }} />
            <p style={{
              textAlign: "center",
              color: "#666",
              fontSize: "1rem",
              margin: 0
            }}>
              © {new Date().getFullYear()} Softwood Textiles. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 
