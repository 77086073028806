import React from "react";

const Careers = () => {
  return (
    <div className="careers-section" style={{ paddingTop: "80px" }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 mb-4">
            <img 
              src="/img/Careers/95da90_8308e26ccb934c60af379394730e77fd~mv2.png" 
              alt="Join Our Team" 
              className="img-fluid rounded shadow"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div className="col-md-6 mb-4">
            <h2 className="mb-4">Careers at Softwood Textiles</h2>
            <div className="careers-content">
              <div className="careers-intro mb-4">
                <h3 className="mb-3">Join Our Team</h3>
                <p className="mb-4">We are always looking for talented individuals to join our growing team. At Softwood Textiles, we offer:</p>
                <ul className="list-unstyled">
                  <li className="mb-2">✓ Competitive compensation packages</li>
                  <li className="mb-2">✓ Professional development opportunities</li>
                  <li className="mb-2">✓ Work-life balance</li>
                  <li className="mb-2">✓ Collaborative work environment</li>
                </ul>
              </div>
              <div className="current-openings">
                <h3 className="mb-3">Current Openings</h3>
                <p className="mb-0">Please send your resume to <a href="mailto:careers@softwoodtextiles.com">careers@softwoodtextiles.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers; 
