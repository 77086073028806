import React, { useState } from "react";
import { Link } from "react-router-dom";

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav style={{
      background: "white",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000
    }}>
      <div className="container">
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px 0"
        }}>
          <Link to="/" style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "inherit"
          }}>
            <img 
              src="/img/Logo.png" 
              alt="Softwood Textiles" 
              style={{
                height: "50px",
                marginRight: "15px"
              }}
            />
            <div>
              <h1 style={{
                fontSize: "1.8rem",
                fontWeight: "600",
                color: "#333",
                margin: 0,
                lineHeight: "1.2"
              }}>Softwood Textiles</h1>
            </div>
          </Link>

          <div className="d-none d-md-flex" style={{ gap: "30px" }}>
            <Link to="/" style={{
              color: "#333",
              textDecoration: "none",
              fontSize: "1.5rem",
              marginLeft:24,
              fontWeight: "500",
              transition: "color 0.3s ease"
            }}>Home</Link>
            <Link to="/why-us" style={{
              color: "#333",
              textDecoration: "none",
              fontSize: "1.5rem",
              marginLeft:24,
              fontWeight: "500",
              transition: "color 0.3s ease"
            }}>Why Us</Link>
            <Link to="/sourcing" style={{
              color: "#333",
              textDecoration: "none",
              fontSize: "1.5rem",
              marginLeft:24,
              fontWeight: "500",
              transition: "color 0.3s ease"
            }}>Sourcing</Link>
            <Link to="/production" style={{
              color: "#333",
              textDecoration: "none",
              fontSize: "1.5rem",
              marginLeft:24,
              fontWeight: "500",
              transition: "color 0.3s ease"
            }}>Production</Link>
            <Link to="/sustainability" style={{
              color: "#333",
              textDecoration: "none",
              fontSize: "1.5rem",
              marginLeft:24,
              fontWeight: "500",
              transition: "color 0.3s ease"
            }}>Sustainability</Link>
            <Link to="/careers" style={{
              color: "#333",
              textDecoration: "none",
              fontSize: "1.5rem",
              marginLeft:24,
              fontWeight: "500",
              transition: "color 0.3s ease"
            }}>Careers</Link>
            <Link to="/blog" style={{
              color: "#333",
              textDecoration: "none",
              fontSize: "1.5rem",
              marginLeft:24,
              fontWeight: "500",
              transition: "color 0.3s ease"
            }}>Blog</Link>
            <Link to="/contact" style={{
              color: "#333",
              textDecoration: "none",
              fontSize: "1.5rem",
              marginLeft:24,
              fontWeight: "500",
              transition: "color 0.3s ease"
            }}>Contact</Link>
          </div>

  
        </div>

      </div>
    </nav>
  );
};
