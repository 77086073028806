import React from "react";
import JsonData from "../data/data.json";
import Timeline from "../components/Timeline";

const WhyUs = () => {

  return (
    <div className="why-us-section" style={{ paddingTop: "60px", paddingBottom: "60px" }}>
      <div className="container">
        {/* Hero Image */}
        <div className="row mb-5">
          <div className="col-12">
            <img 
              src="/img/home/11062b_41ebe635b0f84102aeec826337eb8a5d~mv2.png" 
              alt="Softwood Hero" 
              style={{
                width: "100%",
                height: "300px",
                objectFit: "cover",
                borderRadius: "12px",
                marginBottom: "2rem"
              }}
            />
          </div>
        </div>

        {/* Header */}
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h1 style={{ 
              fontSize: "3.2rem", 
              fontWeight: "600", 
              color: "#333",
              marginBottom: "1.5rem"
            }}>Why Choose Softwood?</h1>
            <p style={{ 
              fontSize: "1.4rem", 
              color: "#666", 
              maxWidth: "800px", 
              margin: "0 auto",
              lineHeight: "1.6",
              marginBottom: 24
            }}>
              With over 15 years of experience in the textile sector, our team possesses a comprehensive understanding of the industry's intricacies. 
              We hold ourselves accountable for every order we undertake, as customer satisfaction is our highest priority.
            </p>
          </div>
        </div>

        {/* Cards */}
        <div className="row g-4">
          {[
            {
              title: "Partnership with Suppliers",
              image: "/img/Why Us/Go Team .png",
              description: "Softwood has established partnerships with several high-quality textile suppliers in Pakistan who adhere to our rigorous standards for quality and service."
            },
            {
              title: "Product Development",
              image: "/img/Why Us/0cea7d379870d04b24b069383bb25ffd.png",
              description: "Our product development team is experienced in researching & providing guidelines to our development teams. We work closely with suppliers to create products that meet market trends."
            },
            {
              title: "Merchandising & Execution",
              image: "/img/Why Us/mer.png",
              description: "We excel at directing and managing the entire product line development process, with our merchandisers and marketers working together seamlessly to deliver the best results."
            },
            {
              title: "Online Order Status",
              image: "/img/Why Us/Image by Myriam Jessier.png",
              description: "We provide real-time updates on the status of all orders to ensure that our clients are informed and at ease. These updates are made on a daily basis."
            },
            {
              title: "Independent Audits",
              image: "/img/Why Us/Image by Scott Graham.png",
              description: "To ensure that we deliver the highest quality products to our clients, we conduct independent audits at every stage of the process."
            },
            {
              title: "Our Team",
              image: "/img/Why Us/car.png",
              description: "Our experienced team brings together diverse expertise in textile manufacturing, quality control, and customer service to deliver exceptional results for our clients."
            }
          ].map((card, index) => (
            <div key={index} className="col-md-4 col-sm-6">
              <div className="card h-100" 
                style={{ 
                  borderRadius: "8px",
                  overflow: "hidden",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                  transition: "all 0.2s ease",
                  cursor: "pointer",
                  border: "1px solid #eee",
                  height:'60%',
                  padding: '8px',
                  marginBottom: 12,
                  marginTop:12
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = "translateY(-5px)";
                  e.currentTarget.style.boxShadow = "0 5px 15px rgba(0,0,0,0.1)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow = "0 2px 8px rgba(0,0,0,0.1)";
                }}>
                <div style={{ position: "relative", height: "180px" }}>
                  <img 
                    src={card.image}
                    alt={card.title}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      
                    }}
                  />
                </div>
                <div className="p-3">
                  <h5 style={{ 
                    fontSize: "1.4rem", 
                    fontWeight: "600", 
                    color: "#333",
                    marginBottom: "0.75rem"
                  }}>{card.title}</h5>
                  <p className="mb-0" style={{ 
                    fontSize: "1.1rem", 
                    lineHeight: "1.5", 
                    color: "#666"
                  }}>
                    {card.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>


        {/* CTA */}
        <div className="row mt-5">
          <div className="col-12 text-center">
            <div className="p-4" style={{ 
              background: "#f8f9fa", 
              borderRadius: "8px",
              border: "1px solid #eee"
            }}>
              <h3 style={{ 
                fontSize: "2rem", 
                fontWeight: "600", 
                color: "#333", 
                marginBottom: "1rem" 
              }}>Ready to Experience the Softwood Difference?</h3>
              <p style={{ 
                fontSize: "1.2rem", 
                color: "#666", 
                marginBottom: "1.5rem" 
              }}>Contact us today to discuss how we can meet your textile needs.</p>
              <a href="/contact" className="btn btn-primary" style={{ 
                padding: "0.5rem 1.5rem",
                fontSize: "1.1rem"
              }}>
                Get in Touch
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs; 
