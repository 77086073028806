import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigation } from "./components/navigation";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import WhyUs from "./pages/WhyUs";
import Sourcing from "./pages/Sourcing";
import Production from "./pages/Production";
import Sustainability from "./pages/Sustainability";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import "./App.css";

const App = () => {
  return (
    <Router>
      <div style={{ 
        display: "flex", 
        flexDirection: "column",
        minHeight: "100vh"
      }}>
        <Navigation />
        <main style={{ flex: 1, paddingTop: "80px" }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/why-us" element={<WhyUs />} />
            <Route path="/sourcing" element={<Sourcing />} />
            <Route path="/production" element={<Production />} />
            <Route path="/sustainability" element={<Sustainability />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blog />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
