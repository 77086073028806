import React, { useState } from "react";
import JsonData from "../data/data.json";

const Production = () => {
  const [activeTab, setActiveTab] = useState(0);

  const productionProcess = [
    {
      title: "Design & Planning",
      description: "Our design team works closely with clients to understand their requirements and create detailed production plans.",
      image: "/img/Production/prod.jpg"
    },
    {
      title: "Material Selection",
      description: "We source high-quality materials from trusted suppliers to ensure the best end product.",
      image: "/img/Production/sourc.jpg"
    },
    {
      title: "Cutting & Sewing",
      description: "Our skilled workers use advanced machinery to cut and sew garments with precision and care.",
      image: "/img/Production/Cutting Process.jpeg"
    },
    {
      title: "Quality Control",
      description: "Every garment undergoes rigorous quality checks to meet international standards.",
      image: "/img/Production/IMG_3299 copy.jpg"
    },
    {
      title: "Packaging & Shipping",
      description: "Finished products are carefully packaged and shipped to clients worldwide.",
      image: "/img/Production/fornt.jpg"
    }
  ];

  return (
    <div className="production-section" style={{ paddingTop: "80px", paddingBottom: "60px" }}>
      <div className="container">
        {/* Hero Section */}
        <div className="row mb-5">
          <div className="col-12">
            <div style={{ 
              position: "relative", 
              height: "400px", 
              overflow: "hidden", 
              borderRadius: "12px",
              boxShadow: "0 4px 12px rgba(0,0,0,0.1)"
            }}>
              <img 
                src="/img/Production/pro1.jpg" 
                alt="Production Facilities" 
                style={{ 
                  width: "100%", 
                  height: "100%", 
                  objectFit: "cover" 
                }}
              />
              <div style={{ 
                position: "absolute", 
                top: 0, 
                left: 0, 
                right: 0, 
                bottom: 0, 
                background: "rgba(0,0,0,0.4)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                color: "white",
                textAlign: "center",
                padding: "0 20px"
              }}>
                <h1 style={{ 
                  fontSize: "3.2rem", 
                  fontWeight: "600", 
                  marginBottom: "1rem",
                  textShadow: "0 2px 4px rgba(0,0,0,0.3)"
                }}>Our Production Excellence</h1>
                <p style={{ 
                  fontSize: "1.4rem", 
                  maxWidth: "800px",
                  textShadow: "0 1px 2px rgba(0,0,0,0.3)"
                }}>
                  State-of-the-art facilities and skilled professionals delivering quality garments
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Introduction Section */}
        <div className="row mb-5">
          <div className="col-12">
            <div className="p-4" style={{ 
              background: "#f8f9fa", 
              borderRadius: "12px",
              border: "1px solid #eee",
              marginTop: "1.5rem",
              padding: 8

            }}>
              <h2 style={{ 
                fontSize: "2.2rem", 
                fontWeight: "600", 
                color: "#333",
                marginBottom: "1.5rem",
                
              }}>Production Capabilities</h2>
              <p style={{ 
                fontSize: "1.2rem", 
                lineHeight: "1.6", 
                color: "#555",
                marginBottom: "1.5rem"
              }}>
                {JsonData.Services[1].text}
              </p>
              <p style={{ 
                fontSize: "1.2rem", 
                lineHeight: "1.6", 
                color: "#555"
              }}>
                Our production facilities are equipped with the latest technology and machinery, allowing us to handle complex orders with precision and efficiency. We maintain strict quality control measures throughout the production process to ensure that every garment meets our high standards.
              </p>
            </div>
          </div>
        </div>

        {/* Production Process */}
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 style={{ 
              fontSize: "2.2rem", 
              fontWeight: "600", 
              color: "#333",
              marginBottom: "1.5rem",
              marginTop: "1.5rem"
            }}>Our Production Process</h2>
            <p style={{ 
              fontSize: "1.2rem", 
              color: "#666", 
              maxWidth: "800px", 
              margin: "0 auto 2rem",
              lineHeight: "1.6"
            }}>
              A comprehensive approach to garment manufacturing
            </p>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-12">
            <div style={{ 
              display: "flex", 
              overflowX: "auto", 
              padding: "10px 0",
              scrollbarWidth: "none",
              msOverflowStyle: "none"
            }}>
              {productionProcess.map((step, index) => (
                <div 
                  key={index} 
                  style={{ 
                    minWidth: "300px", 
                    marginRight: "20px",
                    borderRadius: "8px",
                    overflow: "hidden",
                    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                    transition: "all 0.2s ease",
                    cursor: "pointer",
                    border: "1px solid #eee",
                    paddingRight: 12,
                    paddingLeft:12
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.transform = "translateY(-5px)";
                    e.currentTarget.style.boxShadow = "0 5px 15px rgba(0,0,0,0.1)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.transform = "translateY(0)";
                    e.currentTarget.style.boxShadow = "0 2px 8px rgba(0,0,0,0.1)";
                  }}
                >
                  <div style={{ position: "relative", height: "180px" }}>
                    <img 
                      src={step.image}
                      alt={step.title}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover"
                      }}
                    />
                  </div>
                  <div className="p-3">
                    <h5 style={{ 
                      fontSize: "1.4rem", 
                      fontWeight: "600", 
                      color: "#333",
                      marginBottom: "0.75rem"
                    }}>{step.title}</h5>
                    <p className="mb-0" style={{ 
                      fontSize: "1.1rem", 
                      lineHeight: "1.5", 
                      color: "#666"
                    }}>
                      {step.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Facilities Showcase */}
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 style={{ 
              fontSize: "2.2rem", 
              fontWeight: "600", 
              color: "#333",
              marginBottom: "1.5rem",
              marginTop: "1.5rem"
            }}>Our Facilities</h2>
            <p style={{ 
              fontSize: "1.2rem", 
              color: "#666", 
              maxWidth: "800px", 
              margin: "0 auto 2rem",
              lineHeight: "1.6"
              
            }}>
              State-of-the-art manufacturing facilities
            </p>
          </div>
        </div>

        <div className="row g-4 mb-5">
          {[
            {
              title: "Cutting Room",
              image: "/img/Production/Cutting Process.jpeg",
              description: "Advanced cutting machines for precise fabric cutting"
            },
            {
              title: "Sewing Department",
              image: "/img/Production/Best-Sewing-Machine-for-Denim-and-Jeans.jpeg",
              description: "Modern sewing machines operated by skilled workers"
            },
            {
              title: "Quality Control",
              image: "/img/Production/IMG_3299 copy.jpg",
              description: "Rigorous quality checks at every stage"
            },
            {
              title: "Packaging Area",
              image: "/img/Production/fornt.jpg",
              description: "Efficient packaging and shipping processes"
            }
          ].map((facility, index) => (
            <div key={index} className="col-md-6 col-lg-3">
              <div className="card h-100" 
                style={{ 
                  borderRadius: "8px",
                  overflow: "hidden",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                  transition: "all 0.2s ease",
                  cursor: "pointer",
                  border: "1px solid #eee",
                  paddingRight: 12,
                  paddingLeft:12,
                  height:'45%'
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = "translateY(-5px)";
                  e.currentTarget.style.boxShadow = "0 5px 15px rgba(0,0,0,0.1)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow = "0 2px 8px rgba(0,0,0,0.1)";
                }}>
                <div style={{ position: "relative", height: "180px" }}>
                  <img 
                    src={facility.image}
                    alt={facility.title}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover"
                    }}
                  />
                </div>
                <div className="p-3">
                  <h5 style={{ 
                    fontSize: "1.4rem", 
                    fontWeight: "600", 
                    color: "#333",
                    marginBottom: "0.75rem"
                  }}>{facility.title}</h5>
                  <p className="mb-0" style={{ 
                    fontSize: "1.1rem", 
                    lineHeight: "1.5", 
                    color: "#666"
                  }}>
                    {facility.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Statistics Section */}
        <div className="row mb-5">
          <div className="col-12">
            <div className="p-4" style={{ 
              borderRadius: "12px",
              border: "1px solid #eee",
              marginTop: "1.5rem",
            }}>
              <h2 style={{ 
                fontSize: "2.2rem", 
                fontWeight: "600", 
                color: "#333",
                marginBottom: "1.5rem",
                textAlign: "center",
                marginTop: "1.5rem"
              }}>Production Statistics</h2>
              <div className="row">
                <div className="col-md-3 col-sm-6 mb-4">
                  <div className="text-center p-4" style={{ 
                    background: "white", 
                    borderRadius: "8px",
                    
                  }}>
                    <h3 style={{ 
                      fontSize: "2.5rem", 
                      fontWeight: "700", 
                      color: "#007bff",
                      marginBottom: "0.5rem"
                    }}>150,000</h3>
                    <p style={{ 
                      fontSize: "1.1rem", 
                      color: "#666",
                      marginBottom: 12
                    }}>Monthly Garment Capacity</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 mb-4">
                  <div className="text-center p-4" style={{ 
                    background: "white", 
                    borderRadius: "8px",
                    
                  }}>
                    <h3 style={{ 
                      fontSize: "2.5rem", 
                      fontWeight: "700", 
                      color: "#007bff",
                      marginBottom: "0.5rem"
                    }}>600+</h3>
                    <p style={{ 
                      fontSize: "1.1rem", 
                      color: "#666",
                      marginBottom: 12
                    }}>Trained Professionals</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 mb-4">
                  <div className="text-center p-4" style={{ 
                    background: "white", 
                    borderRadius: "8px",
                    
                  }}>
                    <h3 style={{ 
                      fontSize: "2.5rem", 
                      fontWeight: "700", 
                      color: "#007bff",
                      marginBottom: "0.5rem"
                    }}>50+</h3>
                    <p style={{ 
                      fontSize: "1.1rem", 
                      color: "#666",
                      marginBottom: 12
                    }}>Global Clients</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 mb-4">
                  <div className="text-center p-4" style={{ 
                    background: "white", 
                    borderRadius: "8px",
                    
                  }}>
                    <h3 style={{ 
                      fontSize: "2.5rem", 
                      fontWeight: "700", 
                      color: "#007bff",
                      marginBottom: "0.5rem"
                    }}>98%</h3>
                    <p style={{ 
                      fontSize: "1.1rem", 
                      color: "#666",
                      marginBottom: 12
                    }}>Quality Pass Rate</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default Production; 
